<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <v-layout row wrap px-0 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.site_forms.edit_path">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                {{ item.title.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3>
                </v-flex>
            </v-layout>
            <form @submit.prevent="updateSiteForms" autocomplete="off">
                <v-layout row wrap>

                    <v-flex xs12 lg12 xl12 md3 sm4>
                        <v-text-field v-model="site_forms.site_form_url" type="textarea"
                            :label="$store.getters.language.data.site_forms.site_form_url" dense class="mx-1" filled
                            outlined>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-text-field v-model="site_forms.site_form_text_ku" type="textarea"
                            :label="$store.getters.language.data.site_forms.site_form_text_ku" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-text-field v-model="site_forms.site_form_text_en" type="textarea"
                            :label="$store.getters.language.data.site_forms.site_form_text_en" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-text-field v-model="site_forms.site_form_text_ar" type="textarea"
                            :label="$store.getters.language.data.site_forms.site_form_text_ar" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-textarea rows="3" auto-grow v-model="site_forms.site_from_embed_code" type="textarea"
                            :label="$store.getters.language.data.site_forms.site_from_embed_code" dense class="mx-1"
                            filled outlined>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="primary" class="mx-1" :loading="loading_btn" type="submit">
                            {{$store.getters.language.data.site_forms.add_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import requests from './../../requests/site_forms.request.js'
    export default {
        data() {
            return {
                site_forms: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },

            }
        },
        computed: {

            page() {
                const pageKey = 'SITEFORMS'
                return {}
            }
        },
        mounted() {
            this.id = this.$route.params.id
            this.getOneSiteForms()
        },
        methods: {
            getOneSiteForms() {
                this.loading = true
                requests.getOneSiteForms(this.id).then(r => {
                        if (r.status == 200) {
                            this.site_forms = r.data.site_forms

                            this.loading = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to read SiteForms',
                                color: 'error'
                            }
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read SiteForms',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            updateSiteForms() {
                this.loading = true
                requests.updateSiteForms(this.id, this.site_forms).then(r => {
                        if (r.status == 200) {
                            this.snackbar = {
                                value: true,
                                text: 'SiteForms Updated',
                                color: 'success'
                            }
                            this.loading = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Update Faild',
                                color: 'error'
                            }
                            this.loading = false
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
        },
    }
</script>