
import axios from 'axios';
export default {
    
    async createSiteForms(params)  {
        return await axios.post(`site_forms/create.php` , params)
    },
    async getAllSiteForms()  {
        return await axios.get(`site_forms/readAll.php`)
    },
    async getOneSiteForms(site_form_id)  {
        return await axios.get(`site_forms/readById.php?site_form_id=${site_form_id}`)
    },
    async getSiteFormsByColumn(column , value)  {
        return await axios.get(`site_forms/readByColumn.php?column=${column}&value=${value}`)
    },
    async deleteSiteForms(site_form_id)  {
        return await axios.post(`site_forms/delete.php?site_form_id=${site_form_id}`)
    },
    async updateSiteForms(site_form_id , params)  {
        return await axios.post(`site_forms/update.php?site_form_id=${site_form_id}` , params)
    }
}